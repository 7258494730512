import { AdminProfile } from '@roolz/types/api/profiles'
import { FC } from 'react'
import { flexRender, HeaderGroup, Row, Table as TableType } from '@tanstack/react-table'
import { TableContainerStyled } from '@/pages/Users/Table/styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'

type Props = {
  table: TableType<AdminProfile>
}

export const ProfilesTable: FC<Props> = ({ table }) => {
  return (
    <TableContainerStyled component={Paper}>
      <Table stickyHeader>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup: HeaderGroup<AdminProfile>) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell onClick={header.column.getToggleSortingHandler()} sx={{ fontWeight: 'bold' }} key={header.id}>
                  <TableSortLabel
                    sx={{ display: header.column.getCanSort() ? 'flex' : 'none' }}
                    active={!!header.column.getIsSorted()}
                    direction={header.column.getIsSorted() || header.column.getNextSortingOrder() || undefined}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {table.getRowModel().rows.map((row: Row<AdminProfile>) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainerStyled>
  )
}
