declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

export const theme = {
  // components: {
  //   MuiTypography: {
  //     styleOverrides: {
  //       root: {
  //         color: '#1976d2',
  //       },
  //     },
  //   },
  // },
}
