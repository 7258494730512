import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import {
  DatetimePicker,
  Props as DatetimePickerProps,
  State as DatetimePickerState
} from '@roolz/sdk/components/ui/fields/DatetimePicker/DatetimePicker'
import { Calendar } from '@roolz/icons/Calendar'
import { InputAdornment, TextField } from '@mui/material'
import * as React from 'react'
import { forwardRef, useMemo, useRef, useState } from 'react'
import { padNumber } from '@roolz/sdk/utils/helpers'
import styles from './DatetimeField.module.scss'
import dayjs from '@roolz/sdk/plugins/dayjs'

const MINUTE = 60
const HOUR = 60 * MINUTE

type Unixtime = number

interface Props {
  value: DatetimePickerState
  onChange: (value: DatetimePickerState) => void
  DatetimePickerProps: Partial<DatetimePickerProps>

  [key: string]: any
}

function dateFormatter(date: Unixtime | null) {
  if(!date) return ''

  return dayjs(date).format('DD.MM.YYYY')
}

function timeFormatter(seconds: number | null) {
  if(seconds === null) return ''

  return [
    padNumber(getHours(seconds), 2),
    padNumber(getMinutes(seconds), 2)
  ].join(':')
}

function getHours(seconds: number) {
  return Math.floor(seconds / HOUR)
}

function getMinutes(seconds: number) {
  return Math.floor(seconds % HOUR / MINUTE)
}

export const  DatetimeField = forwardRef(({
  value,
  onChange,
  DatetimePickerProps,
  ...rest
}: Props, ref: any) => {
  const { t } = useSdkTranslation('ui')
  const [open, setOpen] = useState<boolean>(false)

  const fallbackRef = useRef<HTMLInputElement>(null)
  const anchorRef = ref || fallbackRef

  const inputValue: string = useMemo(() => {
    if(value === null) return ''
    let val = ''

    if(value.date_from) {
      val = dateFormatter(value.date_from)
      if(value.date_to && !dayjs(value.date_from).isSame(value.date_to, 'day')){
        val += ' - ' + dateFormatter(value.date_to)
      }
    }

    if(value.time_from !== null) {
      // if dates selected, add dot as divider
      if(val !== '') val += ' • '

      val += timeFormatter(value.time_from)
      if(value.time_to) val += ' - ' + timeFormatter(value.time_to)

      if(value.timezone === null) {
        val += ` (${t('datetime.timezone.local')})`
      } else {
        const tz = Number.parseInt(value.timezone)

        const sign = tz > 0 ? '+' : ''
        val += tz ? ` (GMT ${sign}${tz})` : ` (UTC)`
      }
    }

    return val
  }, [value])


  return (<>
    <TextField
      ref={anchorRef}
      variant='outlined'
      fullWidth
      type='text'
      classes={{ root: styles.input }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Calendar/>
          </InputAdornment>
        )
      }}
      inputProps={{
        autoComplete: 'off'
      }}
      onClick={() => setOpen(open => !open)}

      value={inputValue}
      {...rest}
    />

    <DatetimePicker
      {...DatetimePickerProps}
      open={open}
      setOpen={setOpen}
      anchorRef={anchorRef}
      value={value}
      onChange={onChange}
    />
  </>)
})
