import { styled } from '@mui/material'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { MenuListItem } from '@roolz/sdk/components/ui/MenuList/MenuListItem/MenuListItem'

export const ProfileInfoContainer = styled('div')`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
`

export const Complaints = styled('div')`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
`

export const ActivityInfo = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  font-size: 20px;
  font-weight: bold;

  & .red {
    color: red;
  }
`

export const ActionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const MenuItem = styled(MenuListItem)`
    padding-left: 8px;
    padding-right: 8px;
`
