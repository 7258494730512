import { MainContainer } from '@/pages/Home/styles'
import { MainSidebar } from '@/pages/Home/ui/MainSidebar/MainSidebar'
import { Outlet } from '@tanstack/react-router'

const Home = () => {
  return (
    <MainContainer>
      <MainSidebar />
      <Outlet />
    </MainContainer>
  )
}

export default Home
