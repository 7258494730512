import { styled } from '@mui/material'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'

export const CARDS_COUNTER_KEY = 'card'

export const MainGrid = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
`

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
`

export const Actions = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`

export const Icon = styled(MaterialSymbolIcon)`
  color: ${(props) => props.color ?? 'black'};
`

export const Devices = styled('div')`
  counter-reset: ${CARDS_COUNTER_KEY};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const BlockTitle = styled('div')`
  font-size: 16px;
  font-weight: bold;
`

export const FormBlock = styled('div')`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const ShowMoreDevices = styled('div')`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`