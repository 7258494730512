import { api } from '@/api'
import { useQuerySettings } from '@/shared/components/TableSettings/TableSettings'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from '@/config/theme'
import { Loadable } from '@roolz/sdk/components'
import { SdkContextInterface, SdkProvider } from '@roolz/sdk/SdkContext'
import { Country } from '@roolz/types/custom'
import { useQuery } from '@tanstack/react-query'
import { sortByIndex } from '@roolz/sdk/utils/arrays'
import React, { ReactNode, HTMLAttributes } from 'react'
import { DndProvider } from 'react-dnd'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Trans, useTranslation } from 'react-i18next'
import '@/config/i18n'
import './App.css'

type Props = {
  to: string
  children: ReactNode
} & HTMLAttributes<HTMLElement>

export const AppLink = observer(({
  to,
  children,
  ...rest
}: Props) => {
  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  )
})


const MUITheme = createTheme(theme)

const useConfig = () =>
  useQuery({
    queryKey: ['system'],
    queryFn: async () => {
      const knowledge = await api.knowledge.getCurrencies()
      const system = await api.system.detectMyCountry()

      const supportedCurrencies = sortByIndex(knowledge.data.filter(({ slug }) => !!slug))

      return {
        supportedCurrencies,
        userCountry: system.data,
        supportedCompanyForms: [],
        supportedExchangeRoles: [],
      }
    },
    retry: 1
  })

export const useCountries = () =>
  useQuery({
    queryKey: ['countries'],
    queryFn: async () => (await api.system.getCountryList()).data as Country[],
    refetchOnMount: false
  })

function App({ children }: { children: ReactNode }) {
  const {
    t,
    ready: translationReady,
    i18n: { language, loadLanguages }
  } = useTranslation([], {
    useSuspense: false
  })

  const { isLoading, data: knowledge } = useConfig()
  useCountries()
  useQuerySettings()

  const sdkConfig: SdkContextInterface = {
    useTranslation: (ns?: string) => {
      const {
        t,
        i18n: { language }
      } = useTranslation(ns)

      return { t, lang: language }
    },

    Trans: Trans,
    lang: language,
    getServerDatetime: () => new Date(),
    knowledge: knowledge ?? {
      supportedCurrencies: [],
      userCountry: {},
      supportedCompanyForms: [],
      supportedExchangeRoles: [],
    },
    api,
    AppLink
  }

  if(!translationReady || isLoading) {
    return <Loadable
      loading
      style={{
        width: '100vw',
        height: '100vh'
      }}
    />
  }

  return (
    <ThemeProvider theme={MUITheme}>
      <SdkProvider value={sdkConfig}>
        <DndProvider backend={HTML5Backend}>
          <CssBaseline enableColorScheme/>
          <div className='App'>{children}</div>
        </DndProvider>
      </SdkProvider>
    </ThemeProvider>
  )
}

export default App
