import { ComponentProps, forwardRef } from 'react'
import cn from 'classnames'
import { InputAdornment, TextField } from '@mui/material'
import { Backspace } from '@roolz/icons/Backspace'
import { Search } from '@roolz/icons/Search'
import styles from './SearchInput.module.scss'

interface Props extends ComponentProps<typeof TextField> {
  value: string
  handleClear?: () => void
}

export const SearchInput = forwardRef(({
  handleClear,
  ...rest
}: Props, ref: any) => {

  return (
    <TextField
      ref={ref}
      variant='outlined'
      type='text'
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment
            position='start'
            className={styles.startAdornment}
          >
            <Search/>
          </InputAdornment>
        ),
        endAdornment: handleClear && (
          <InputAdornment
            onClick={handleClear}
            className={cn(styles.clear, {
              [styles.clear__show]: rest.value?.length
            })}
            position='end'
          >
            <Backspace/>
          </InputAdornment>
        )
      }}
      {...rest}
    />
  )
})
