import { api } from '@/api/index'
import { AuthTokenInfo, LoginByEmailRequest } from '@roolz/types/api/profiles'

const TOKEN_KEY = 'token'
const TOKEN_EXPIRES = 'expires_at'

class AuthService {
  private isRefreshing = false

  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  }

  setCredentials(credentials: AuthTokenInfo) {
    localStorage.setItem(TOKEN_KEY, credentials.token)
    localStorage.setItem(TOKEN_EXPIRES, credentials.expires_at)
  }

  getTokenExpiresAt() {
    return localStorage.getItem(TOKEN_EXPIRES)
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(TOKEN_EXPIRES)
  }

  async loginWithEmail(body: LoginByEmailRequest) {
    const { data: { auth_token }} = await api.auth.loginByEmail(body)

    this.setCredentials(auth_token)
  }

  async refreshTokens() {
    const token = this.getToken()

    if(!token) return

    const { data } = await api.auth.refreshToken({
      token
    })
    this.setCredentials(data)
  }

  async refreshTokenIfNecessary() {
    if(this.isRefreshing) return
    const tokenExpiresAt = this.getTokenExpiresAt()

    if(tokenExpiresAt) {
      const expTime = new Date(tokenExpiresAt).getTime()
      const now = new Date().getTime()
      const timeRemains = expTime - now

      if(timeRemains < 5 * 60 * 1000) {
        this.isRefreshing = true
        return this.refreshTokens()
          .catch(() => {
            this.logout()
          })
          .finally(() => {
            this.isRefreshing = false
          })
      }
    }
    return false
  }
}

const authService = new AuthService()

export {
  authService,
  AuthService
}
