import cn from 'classnames'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import styles from './RangePicker.module.scss'

interface Props {
  // Value in start position
  min?: number
  // Value in end position
  max?: number
  cursorRadius?: number
  value?: number
  onChange?: (value: number) => void
  className?: string
}

export const RangePicker = ({
  min = 0,
  max = 100,
  value = 0,
  cursorRadius = 7,
  className,
  onChange
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [positionPercent, setPositionPercent] = useState<number>(0)

  function handleCursorMove<El>(event: PointerEvent | MouseEvent<El>) {
    const containerRect = containerRef.current?.getBoundingClientRect()
    if(!containerRect) return

    const rangeStartX = containerRect.x
    const rangeEndX = containerRect.x + containerRect.width
    const rangeWidth = containerRect.width
    const cursorX = event.pageX

    let newPositionPercent: number

    if(cursorX <= rangeStartX) {
      newPositionPercent = 0
    } else if(cursorX >= rangeEndX) {
      newPositionPercent = 100
    } else {
      newPositionPercent = (cursorX - rangeStartX) / rangeWidth * 100
    }
    setPositionPercent(newPositionPercent)

    if(onChange) {
      onChange((newPositionPercent / 100) * (max - min) + min)
    }
  }

  function handlePointerUp() {
    setIsDragging(false)
    window.removeEventListener('pointermove', handleCursorMove)
    window.removeEventListener('pointerup', handlePointerUp)
  }

  function handlePointerDown<El>(event: MouseEvent<El>) {
    setIsDragging(true)
    window.addEventListener('pointermove', handleCursorMove)
    window.addEventListener('pointerup', handlePointerUp)

    handleCursorMove<El>(event)
  }

  /**
   * START FIX
   * Fix safari changing cursor when mousedown and move
   */
  function handleSelectStart(event: any) {
    event.preventDefault()
  }

  useEffect(() => {
    if(isDragging) {
      document.addEventListener('selectstart', handleSelectStart)
    } else {
      document.removeEventListener('selectstart', handleSelectStart)
    }

    return () => document.removeEventListener('selectstart', handleSelectStart)
  }, [isDragging])
  /**
   * END FIX
   */

  useEffect(() => {
    setPositionPercent((value - min) / (max - min) * 100)
  }, [value])


  return (
    <div
      className={cn(styles.container, className)}
      onPointerDown={handlePointerDown}
      ref={containerRef}
    >
      <div
        className={styles.line}
        // style={{ paddingLeft: cursorRadius / 2, paddingRight: cursorRadius / 2 }}
      ></div>
      <div
        className={styles.selectedLine}
        style={{
          // paddingLeft: cursorRadius / 2, paddingRight: cursorRadius / 2
          width: `${positionPercent}%`
        }}
      ></div>
      <div
        className={styles.cursor}
        style={{
          height: cursorRadius * 2,
          width: cursorRadius * 2,
          left: `calc(${positionPercent}% - ${cursorRadius}px)`
        }}
      ></div>
    </div>
  )
}

