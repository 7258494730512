import { api } from '@/api'
import { indexRoute } from '@/config/routes'
import { userRoute } from '@/pages/User/User'
import { Filters, getDefaultDate } from '@/pages/Users/Filters/Filters'
import {
  getBodyFromFilters,
  ProfilesTableSettings,
  profilesSettingsMap,
  UsersFilters,
} from '@/pages/Users/model'
import {
  Action,
  BottomBlock,
  FiltersBlock,
  LinkStyled,
  SubmitActionsContainer,
  UsersCount,
  Wrapper,
} from '@/pages/Users/styles'
import { ProfilesTable } from '@/pages/Users/Table/Table'
import {
  getItemsFromDefaults,
  TableSettings,
  useMutationSettings,
  useQuerySettings,
} from '@/shared/components/TableSettings/TableSettings'
import { PageContainer } from '@/shared/styles/PageContainer'
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Popover,
  PopoverProps,
  Radio,
  RadioGroup,
  TablePagination,
  Typography,
} from '@mui/material'
import { Settings, SettingsResponse } from '@roolz/api/api/identity/admin.api'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import SettingsIcon from '@material-symbols/svg-400/rounded/settings.svg'
import FilterIcon from '@material-symbols/svg-400/rounded/filter_alt.svg'
import OpenProfileIcon from '@material-symbols/svg-400/rounded/open_in_new.svg'
import { Loadable } from '@roolz/sdk/components'
import { SearchInput } from '@roolz/sdk/components/ui/fields/SearchInput/SearchInput'
import { AdminProfile } from '@roolz/types/api/profiles'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { createRoute, Outlet } from '@tanstack/react-router'
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  PaginationState,
  Table,
  Row,
  Column,
  Cell,
  SortingState,
} from '@tanstack/react-table'
import { createForm } from 'final-form'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { cloneDeep } from 'lodash'
import { LoadingBackdrop } from '@/shared/components/LoadingBackdrop/LoadingBackdrop'

const settingsPopoverProps: Omit<PopoverProps, 'open'> = {
  slotProps: {
    paper: {
      sx: {
        padding: 2,
        borderRadius: 2,
        maxWidth: 500,
      },
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
}

export type TableCellProps<TData = AdminProfile> = {
  table: Table<TData>
  row: Row<TData>
  column: Column<TData>
  cell: Cell<TData, keyof TData>
  getValue: () => any
  renderValue: () => any
}

const columnHelper = createColumnHelper<AdminProfile>()

const defaultCell = (props: TableCellProps) => props.getValue()

const emptyArray: AdminProfile[] = []

const defaultFilters: UsersFilters = {
  search_name: '',
  product: null,
  reg_date: getDefaultDate(),
  countries: [],
  last_action_date: getDefaultDate(),
  is_profile_unfilled: false,
  is_profile_banned: false,
  is_profile_deleted: false,
  has_not_companies: false,
}

const columns: ColumnDef<AdminProfile>[] = [
  columnHelper.display({
    id: 'openProfile',
    enableSorting: false,
    cell: (props) => {
      return (
        <LinkStyled
          to={userRoute.to}
          params={{
            userId: (props.row.getValue('id') ?? '') as string,
          }}>
          <MaterialSymbolIcon size={24} icon={<OpenProfileIcon />} />
        </LinkStyled>
      )
    },
  }),
  ...Object.values(profilesSettingsMap)
    .map((item) =>
    // @ts-ignore
      columnHelper.accessor(item.accessor, {
        id: item.id,
        header: item.title.toUpperCase(),
        enableHiding: true,

        cell: item.render ? item.render : defaultCell,
      }),
    ),
]

const getDefaultPagination = () => ({
  pageIndex: 0,
  pageSize: 50,
})

const getDefaultSorting = (allSettings: SettingsResponse) => {
  const savedSort = allSettings?.profile_list_config?.order_by

  if (!allSettings || !savedSort) {
    return []
  }

  return [
    {
      id: savedSort.key,
      desc: savedSort.value === 'desc',
    },
  ]
}

export const Users = () => {
  const [filters, setFilters] = useState(defaultFilters)
  const [sorting, setSorting] = useState<SortingState>([])
  const [initSortFromSettings, setInitSortFromSettings] = useState(false)
  const [pagination, setPagination] = useState<PaginationState>(getDefaultPagination)

  const { data: allSettings } = useQuerySettings()
  const { mutate: saveSettings } = useMutationSettings()

  const settings = allSettings?.profile_list_config?.settings

  const columnVisibility = settings
    ? Object.entries(settings).reduce(
        (acc, [key, item]) => {
          acc[key] = item.value
          return acc
        },
        {} as Record<keyof ProfilesTableSettings, boolean>,
      )
    : getItemsFromDefaults().reduce(
        (acc, item) => {
          acc[item.id] = item.value
          return acc
        },
        {} as Record<keyof ProfilesTableSettings, boolean>,
      )

  const columnOrder = settings
    ? Object.entries(settings)
        .sort(([_a, a], [_b, b]) => a.index - b.index)
        .map(([key, _]) => key)
    : getItemsFromDefaults()
        .sort((a, b) => a.index - b.index)
        .map((item) => item.id)

  useEffect(() => {
    if(!allSettings) return

    const sort = getDefaultSorting(allSettings)
    setSorting(sort)
    setInitSortFromSettings(true)
  }, [allSettings])

  const onSubmit = (values: UsersFilters) => {
    setFilters(values)
  }

  const [form] = useState(() =>
    createForm<UsersFilters>({
      initialValues: defaultFilters,
      onSubmit,
    }),
  )

  const settingsAnchorRef = useRef(null)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  const { data, isLoading, isFetching, isError } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['profiles', pagination, filters, sorting],
    queryFn: async () => {
      const sortedBy = sorting[0]?.id
      const direction = sorting[0]?.desc === true ? '-' : ''
      const { data } = await api.profiles.getAllProfiles({
        params: {
          offset: pagination.pageSize * pagination.pageIndex,
          limit: pagination.pageSize,
          order_by: sortedBy && `${direction}${sortedBy}`,
        },
        body: getBodyFromFilters(filters),
      })

      return data
    },
    enabled: initSortFromSettings,
  })

  const handleSortingChange = (sort: (prev?: SortingState) => SortingState) => {
    if(!allSettings) return

    setSorting(sort)

    const newSettings = cloneDeep(allSettings)

    const actualSort = sort(sorting)?.[0]
    const sortedBy = actualSort?.id
    const direction = actualSort?.desc ? 'desc' : 'asc'

    if (sortedBy) {
      if(!newSettings.profile_list_config) {
        newSettings.profile_list_config = {} as Settings
      }
      newSettings.profile_list_config.order_by = {
        key: sortedBy,
        value: direction,
      }
    } else {
      delete newSettings.profile_list_config?.order_by
    }

    saveSettings(newSettings)
  }

  const tableData = useMemo(() => {
    return !isLoading && !isError && data?.items ? data.items : emptyArray
  }, [isLoading, data?.items])

  const table = useReactTable<AdminProfile>({
    data: tableData,
    columns,
    state: {
      pagination,
      columnVisibility,
      columnOrder: ['openProfile', ...columnOrder],
      sorting,
    },
    sortDescFirst: true,
    onSortingChange: handleSortingChange,
    getCoreRowModel: getCoreRowModel(),
  })

  const showLoader = isLoading || isFetching

  return (
    <PageContainer style={{ position: 'relative' }}>
      <Popover open={isSettingsOpen} anchorEl={settingsAnchorRef.current} {...settingsPopoverProps}>
        <TableSettings onClose={() => setIsSettingsOpen(false)} />
      </Popover>

      <Form form={form} onSubmit={() => {}} initialValues={defaultFilters}>
        {({ form }) => {
          return (
            <>
              <FiltersBlock>
                <Field name='search_name'>
                  {({ input }) => (
                    <SearchInput
                      size='small'
                      fullWidth={false}
                      sx={{
                        minWidth: 300,
                      }}
                      placeholder='Search'
                      handleClear={() => input.onChange('')}
                      {...input}
                    />
                  )}
                </Field>

                <Action
                  tabIndex={0}
                  className={isFiltersOpen ? 'active' : ''}
                  role='button'
                  onClick={() => setIsFiltersOpen((p) => !p)}>
                  <MaterialSymbolIcon icon={<FilterIcon />} />
                  <Typography>фильтр</Typography>
                </Action>
                <Action
                  tabIndex={0}
                  className={isSettingsOpen ? 'active' : ''}
                  role='button'
                  ref={settingsAnchorRef}
                  onClick={() => setIsSettingsOpen(true)}>
                  <MaterialSymbolIcon icon={<SettingsIcon />} />
                  <Typography>настройка столбцов</Typography>
                </Action>

                <UsersCount>
                  <Typography>количество пользователей:</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>{data?.total}</Typography>
                </UsersCount>
              </FiltersBlock>

              <Collapse in={isFiltersOpen}>
                <Filters form={form} />
              </Collapse>

              <Wrapper>
                <BottomBlock>
                  <Field name='product'>
                    {({ input: { value, onChange, name } }) => (
                      <FormControl>
                        <RadioGroup row value={value} onChange={onChange} name={name}>
                          <FormControlLabel value='' control={<Radio />} label='Все' />
                          <FormControlLabel value='business' control={<Radio />} label='Business' />
                          <FormControlLabel value='driver' control={<Radio />} label='Driver' />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>
                  <Loadable loading={showLoader}>
                    <SubmitActionsContainer>
                      <Button
                        variant='outlined'
                        size='large'
                        onClick={() => {
                          form.reset()
                          setPagination(getDefaultPagination)
                          onSubmit(form.getState().values)
                        }}>
                        Очистить фильтры
                      </Button>
                      <Button
                        variant='contained'
                        size='large'
                        onClick={() => {
                          setPagination(getDefaultPagination)
                          onSubmit(form.getState().values)
                        }}>
                        Найти
                      </Button>
                    </SubmitActionsContainer>
                  </Loadable>
                </BottomBlock>

                {!!data && <ProfilesTable table={table} />}

                <TablePagination
                  style={{
                    overflow: 'unset',
                  }}
                  component='div'
                  page={pagination.pageIndex}
                  count={data?.total ?? 0}
                  rowsPerPage={pagination.pageSize}
                  onRowsPerPageChange={(e) =>
                    setPagination((p) => ({
                      ...p,
                      pageSize: Number(e.target.value),
                    }))
                  }
                  onPageChange={(_, page) =>
                    setPagination((p) => ({
                      ...p,
                      pageIndex: Number(page),
                    }))
                  }
                />

                {showLoader && <LoadingBackdrop loading />}
              </Wrapper>
            </>
          )
        }}
      </Form>
    </PageContainer>
  )
}

export const usersRoute = createRoute({
  getParentRoute: () => indexRoute,
  path: 'users',
  component: () => <Outlet />,
})

export const usersIndexRoute = createRoute({
  getParentRoute: () => usersRoute,
  path: '/',
  component: Users,
})
