import { DEFAULT_LANGUAGE } from '@/config/locales'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { BackendModule, ReadCallback, Services } from 'i18next'
import messages from '@roolz/i18n'

type ResourceFetcher = () => Promise<{
  __esModule?: true
  default?: Record<string, unknown>
  [key: string]: unknown
}>

export interface AsyncBackendOptions {
  resources?: {
    [language: string]: ResourceFetcher | Record<string, ResourceFetcher>
  }
}


const resources = {
  es: () => import('@roolz/i18n/es_ES/index.js').then((mod) => mod.default),
  ru: () => import('@roolz/i18n/ru/index.js').then((mod) => mod.default),
  en: () => import('@roolz/i18n/en/index.js').then((mod) => mod.default),
  de: () => import('@roolz/i18n/de/index.js').then((mod) => mod.default),
  fr: () => import('@roolz/i18n/fr_FR/index.js').then((mod) => mod.default),
  hi: () => import('@roolz/i18n/hi_IN/index.js').then((mod) => mod.default),
  pl: () => import('@roolz/i18n/pl_PL/index.js').then((mod) => mod.default),
  tr: () => import('@roolz/i18n/tr/index.js').then((mod) => mod.default),
  uk: () => import('@roolz/i18n/uk/index.js').then((mod) => mod.default),
  zh: () => import('@roolz/i18n/zh_CN/index.js').then((mod) => mod.default),
}

export type SupportedLocale = keyof typeof resources
export const LOCALES = Object.fromEntries(Object.keys(resources).map((lang) => [lang, lang]))

i18n
  // .use(AsyncBackend)
  // .use(LazyImportPlugin)
  // .use(Backend) // or any other backend implementation

  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: false,
    // backend: { resources },
    resources: messages,
    //  lng: uiStore.language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: Object.values(LOCALES),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
