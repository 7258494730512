import { TableCellProps } from '@/pages/Users/Users'
import { ProductAvatar } from '@/shared/components/ProductAvatar/ProductAvatar'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { State as DateTime } from '@roolz/sdk/components/ui/fields/DatetimePicker/DatetimePicker'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { AdminProfile, GetAllAdminProfilesRequest, Product } from '@roolz/types/api/profiles'
import { Country } from '@roolz/types/custom'
import { ISOString } from '@roolz/types/scalars'
import { ReactNode } from 'react'

export type TableProfile = {
  id: string
  name: string
  company: string
  product: Product
  registration_date: ISOString
  phone: string
  email: string
  country: string
  nickname: string
}

const getProfileFullName = (profile: AdminProfile) => {
  const {
    first_name,
    middle_name,
    last_name
  } = profile?.profile_view_info ?? {}

  return `${last_name ?? ''} ${middle_name ?? ''} ${first_name ?? ''}`
}

const getCompanyInfo = ({ companies }: AdminProfile) => {
  if(!companies) return 'Физ. лицо'
  const activeCompanyName = companies[0]?.name ?? ''
  const companiesCount = companies.length

  return companiesCount > 1
    ? `${activeCompanyName} (${companiesCount})`
    : companiesCount
      ? activeCompanyName
      : 'Физ. лицо'
}

export const getFormattedDate = (date?: string) => {
  return date ? dayjs(date).format('DD.MM.YYYY hh:mm') : ''
}

export type UsersFilters = {
  search_name: string,
  product: Product | null
  reg_date: DateTime | null
  countries: Country[]
  last_action_date: DateTime | null
  is_profile_unfilled: boolean
  is_profile_banned: boolean
  is_profile_deleted: boolean
  has_not_companies: boolean
}

const getIsoDate = (date: number | null | undefined) => {
  if (!date) return

  return dayjs(date).toISOString()
}

export const getBodyFromFilters = (filters: UsersFilters): GetAllAdminProfilesRequest['body'] => {
  const { reg_date, last_action_date, countries, ...rest } = filters

  return {
    country_ids: countries.map(({ id }) => id),
    reg_date_from: getIsoDate(reg_date?.date_from),
    reg_date_to: getIsoDate(reg_date?.date_to),
    last_action_to: getIsoDate(last_action_date?.date_from),
    allow_deleted: true,
    ...rest
  }
}

export type SettingsItem = {
  id: string
  value: boolean
  index: number
}

export const profilesSettingsMap: Record<string, {
  id: string
  title: string
  defaultValue: boolean
  accessor: string
  render?: (props: TableCellProps) => ReactNode
}> = {
  product: {
    id: 'product',
    title: 'Продукт регистрации',
    defaultValue: true,
    accessor: 'first_product',
    render: ({ cell }) => <ProductAvatar product={cell.getValue()} />,
  },
  created_at: {
    id: 'created_at',
    title: 'Дата регистрации пользователя',
    defaultValue: true,
    accessor: 'created_at',
    render: (props) => getFormattedDate(props.row.original.created_at)
  },
  full_name: {
    id: 'full_name',
    title: 'Имя',
    defaultValue: true,
    accessor: 'full_name',
    render: (props) => getProfileFullName(props.row.original)
  },
  companies: {
    id: 'companies',
    title: 'Компании пользователя',
    defaultValue: true,
    accessor: 'companies',
    render: (props) => getCompanyInfo(props.row.original)
  },
  own_phone: {
    id: 'own_phone',
    title: 'Телефон',
    defaultValue: true,
    accessor: 'profile_view_info.own_phone',
  },
  own_email: {
    id: 'own_email',
    title: 'Email',
    defaultValue: true,
    accessor: 'profile_view_info.own_email',
  },
  country: {
    id: 'country',
    title: 'Страна',
    defaultValue: true,
    accessor: 'country',
    render: (props) => props.row.original.country?.name
  },
  nickname: {
    id: 'nickname',
    title: 'Никнейм',
    defaultValue: true,
    accessor: 'nickname',
  },
  avatar: {
    id: 'avatar',
    title: 'Аватар',
    defaultValue: false,
    accessor: 'avatar',
    render: ({ row: { original: profile }}) => (
      <Avatar
        width={50}
        type='profile'
        color_code={profile.color}
        first_name={profile.profile_view_info?.first_name}
        last_name={profile.profile_view_info?.last_name}
        avatarUrl={profile.profile_view_info?.avatar}
    />)
  },
  id: {
    id: 'id',
    title: 'ID профиля',
    defaultValue: false,
    accessor: 'id',
  },
  last_activity: {
    id: 'last_activity',
    title: 'Последняя активность',
    defaultValue: false,
    accessor: 'last_action_time',
    render: (props) => getFormattedDate(props.row.original.last_action_time)
  },
  last_activity_product: {
    id: 'last_activity_product',
    title: 'Продукт последней активности',
    defaultValue: false,
    accessor: 'last_activity_product'
  },
  banned_at: {
    id: 'banned_at',
    title: 'Забаненные пользователи',
    defaultValue: false,
    accessor: 'banned_at',
  },
  is_filled: {
    id: 'is_filled',
    title: 'Заполненный профиль',
    defaultValue: false,
    accessor: 'is_filled',
    render: (props) => props.row.original.is_filled ? 'Да' : 'Нет'
  },
  // с
  //todo
  country_by_ip: {
    id: 'country_by_ip',
    title: 'Страна по IP',
    defaultValue: false,
    accessor: 'country_by_ip',
  },
  last_login_country_code: {
    id: 'last_login_country_code',
    title: 'Страна последнего логина',
    defaultValue: false,
    accessor: 'last_login_country_code',
  },
  site: {
    id: 'site',
    title: 'Сайт',
    defaultValue: false,
    accessor: 'profile_view_info.site',
  },
  work_phone: {
    id: 'work_phone',
    title: 'Рабочий Телефон',
    defaultValue: false,
    accessor: 'profile_view_info.work_phone',
  },
  phone: {
    id: 'phone',
    title: 'Авторизационный номер телефона',
    defaultValue: false,
    accessor: 'phone',
  },
  phone_verified: {
    id: 'phone_verified',
    title: 'Подтверждение авторизационного номера телефона',
    defaultValue: false,
    accessor: 'phone_verified',
    render: (props) => props.row.original.phone_verified ? 'Да' : 'Нет'

  },
  work_email: {
    id: 'work_email',
    title: 'Рабочий email',
    defaultValue: false,
    accessor: 'profile_view_info.work_email',
  },
  email: {
    id: 'email',
    title: 'Авторизационный email',
    defaultValue: false,
    accessor: 'email',
  },
  email_verified: {
    id: 'email_verified',
    title: `Подтверждение авторизационного email`,
    defaultValue: false,
    accessor: 'email_verified',
    render: (props) => props.row.original.email_verified ? 'Да' : 'Нет'
  },
  skype: {
    id: 'skype',
    title: 'Скайп',
    defaultValue: false,
    accessor: 'profile_view_info.skype',
  },
  devices: {
    id: 'devices',
    title: 'Девайсы пользователя',
    defaultValue: false,
    accessor: 'devices',
    render: (props) => 'TODO'

  },
  reg_method: {
    id: 'reg_method',
    title: 'Метод регистрации',
    defaultValue: false,
    accessor: 'reg_method',
  },
  deleted_at: {
    id: 'deleted_at',
    title: 'Дата удаления аккаунта',
    defaultValue: false,
    accessor: 'deleted_at',
    render: (props) => getFormattedDate(props.row.original.deleted_at ?? undefined)
  },
  deletion_reason: {
    id: 'deletion_reason',
    title: 'Причина удаления аккаунта',
    defaultValue: false,
    accessor: 'deletion_reason',
  },
  public_offers: {
    id: 'public_offers',
    title: 'Публичные оферты',
    defaultValue: false,
    accessor: 'public_offers',
  },
  private_offers: {
    id: 'private_offers',
    title: 'Приватные оферты',
    defaultValue: false,
    accessor: 'private_offers',
  },
  total_offers: {
    id: 'total_offers',
    title: 'Все оферты',
    defaultValue: false,
    accessor: 'total_offers',
  },
  cargo_offers: {
    id: 'cargo_offers',
    title: 'Грузовые оферты',
    defaultValue: false,
    accessor: 'cargo_offers',
  },
  transport_offers: {
    id: 'transport_offers',
    title: 'Транспортные оферты',
    defaultValue: false,
    accessor: 'transport_offers',
  },
}

export type ProfilesTableSettings = Record<keyof typeof profilesSettingsMap, { value: boolean, index: number }>