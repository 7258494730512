import { styled } from '@mui/material'
import { Loadable } from '@roolz/sdk/components'

export const LoadingBackdrop = styled(Loadable)`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
` as typeof Loadable