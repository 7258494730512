import { styled } from '@mui/material'

export const Container = styled('div')`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Form = styled('form')`
  min-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => theme.spacing(1)};

  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`
