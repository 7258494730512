import { usersRoute } from '@/config/routes'
import { useGetProfileQuery } from '@/pages/User/model'
import { Header } from '@/pages/User/ui/Header/Header'
import { ProfileForm } from '@/pages/User/ui/ProfileForm/ProfileForm'
import { EditContextProvider } from '@/shared/context/editContext'
import { PageContainer } from '@/shared/styles/PageContainer'
import { Tabs, Tab } from '@mui/material'
import { createRoute, useNavigate } from '@tanstack/react-router'
import { SyntheticEvent, useState } from 'react'

const tabs = [
  {
    id: 1,
    label: 'О пользователе'
  },
  {
    id: 2,
    label: 'Оферты'
  },
  {
    id: 3,
    label: 'Жалобы'
  },
]

export const User = () => {
  const { userId } = userRoute.useParams()
  const navigate = useNavigate()
  const { data, error, isFetching } = useGetProfileQuery(userId)
  const profile = data?.data

  if(error) {
    navigate(usersRoute)
  }

  const [selectedTabId, setSelectedTabId] = useState(1)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTabId(newValue)
  }

  if(isFetching || error || !profile) {
    return null
  }

  return (
    <PageContainer>
      <Header profile={profile}/>

      <Tabs value={selectedTabId} onChange={handleChange}>
        {tabs.map(({ label, id }) => (
          <Tab key={id} value={id} label={label} />
        ))}
      </Tabs>
      {selectedTabId === tabs[0].id && (
        <ProfileForm profile={profile}/>
      )}
    </PageContainer>
  )
}

export const userRoute = createRoute({
  path: '$userId',
  getParentRoute: () => usersRoute,
  component: () => (
    <EditContextProvider>
      <User/>
    </EditContextProvider>
  )
})
