import { CARDS_COUNTER_KEY } from '@/pages/User/ui/ProfileForm/styles'
import { styled } from '@mui/material'

export const CardContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  &:before {
    counter-increment: ${CARDS_COUNTER_KEY};
    content: counter(${CARDS_COUNTER_KEY});
    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    border-radius: 4px;
    padding: 0 4px;
    font-size: 14px;
      
    position: absolute;
    left: -24px;
  }
`

export const DeviceActions = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const IdsContainer = styled('div')<{ red: boolean }>`
    color: ${({ red }) => red ? 'red' : 'inherit' }}
`

export const TooltipContent = styled('div')`
    font-size: 16px;
`