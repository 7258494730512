import { useBanDeviceMutation, useUnbanDeviceMutation } from '@/pages/User/model'
import { CardContainer, DeviceActions, IdsContainer, TooltipContent } from '@/pages/User/ui/DeviceCard/styles'
import { getFormattedDate } from '@/pages/Users/model'
import { Label } from '@/shared/components/Label'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material'
import { AdminDevice, AdminProfile } from '@roolz/types/api/profiles'
import { Button } from '@mui/material'
import RoolzBtn from '@roolz/sdk/components/ui/buttons/Button/Button'
import { useState } from 'react'

type Props = {
  device: AdminDevice
  profileId: AdminProfile['id']
}

const BanConfirmModal = ({
  open,
  handleClose,
  handleBan
}: {
  open: boolean
  handleClose: () => void
  handleBan: () => void
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        Бан девайса
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Вы уверены, что хотите забанить этот девайс?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Нет</Button>
        <Button
          onClick={() => {
            handleBan()
            handleClose()
          }}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const DeviceCard = ({ device, profileId }: Props) => {
  const {
    os_type,
    client_name,
    last_login_at,
    logouted_at,
    created_at,
    installation_id,
    logined_users,
    ban
  } = device

  const otherUsers = logined_users.filter((id) => id !== profileId)

  const { mutate: banDevice } = useBanDeviceMutation(installation_id, profileId)
  const { mutate: unbanDevice } = useUnbanDeviceMutation(installation_id, profileId)

  const isBanned = !!ban
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const handleBan = () => {
    banDevice()
  }

  return (
    <>
      <CardContainer>
        <DeviceActions>
          <Tooltip
            disableHoverListener={!otherUsers.length}
            title={
              <TooltipContent hidden={!otherUsers.length}>
                <div>ID залогиненных юзеров:</div>
                {otherUsers.map((id) => (<div key={id}>{id}</div>))}
              </TooltipContent>
            }
          >
            <div>
              <Label>ID девайса</Label>
              <IdsContainer red={!!otherUsers.length}>
                <div>{installation_id}</div>
              </IdsContainer>
            </div>
          </Tooltip>
          <div>
            {isBanned
              ? (
                <RoolzBtn
                  variant='outlined'
                  onClick={unbanDevice}
                >
                  Разбанить девайс
                </RoolzBtn>
              ) : (
                <RoolzBtn
                  variant='outlined'
                  color='danger'
                  onClick={() => setOpen(true)}
                >
                  Забанить девайс
                </RoolzBtn>
              )}
          </div>
        </DeviceActions>
        <div>
          <Label>Название устройства</Label>
          <div>{client_name}</div>
        </div>
        <div>
          <Label>Тип операционной системы</Label>
          <div>{os_type}</div>
        </div>
        <div>
          <Label>Время первого логина с девайса</Label>
          <div>{getFormattedDate(created_at)}</div>
        </div>
        <div>
          <Label>Время последнего логина</Label>
          <div>{getFormattedDate(last_login_at)}</div>
        </div>
        <div>
          <Label>Время последнего разлогина</Label>
          <div>{getFormattedDate(logouted_at)}</div>
        </div>
      </CardContainer>

      <BanConfirmModal open={open} handleClose={handleClose} handleBan={handleBan}/>
    </>

  )
}
