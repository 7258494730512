export const LOCALE_FLAGS: any = {
  de: 'de',
  en: 'gb',
  es: 'es',
  fr: 'fr',
  hi: 'in',
  pl: 'pl',
  ru: 'ru',
  tr: 'tr',
  uk: 'ua',
  zh: 'cn',
}

export const DEFAULT_LANGUAGE = 'en'
