import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { Product } from '@roolz/types/api/profiles'

type Props = {
  product: Product | string
  width?: number
}

export const ProductAvatar = ({ product, width = 50 }: Props) => {
  const color = product === 'driver'
    ? '#56AC9F'
    : '#4778EE'

  return (
    <Avatar
      width={width}
      type='company'
      color_code={color}
      first_name={product}
    />
  )
}