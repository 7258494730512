import { configService } from '@/api/config.service'
import { queryClient } from '@/index'
import { ProfilesTableSettings, SettingsItem, profilesSettingsMap } from '@/pages/Users/model'
import { Items } from '@/shared/components/TableSettings/styles'
import { Box, Button } from '@mui/material'
import { SettingsResponse } from '@roolz/api/api/identity/admin.api'
import { Loadable } from '@roolz/sdk/components'
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { TableSettingsItem } from './TableSettingsItem'

const reorder = <T extends ArrayLike<any>>(list: T, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const useQuerySettings = () => useQuery({
  queryKey: ['settings'],
  queryFn: async () => {
    const { data } = await configService.getSettings()

    return data
  },
  refetchOnMount: false,
})

export const useMutationSettings = () => useMutation({
  mutationFn: configService.saveSettings,

  onSuccess: ({ data }) => {
    queryClient.setQueryData(['settings'], data)
  }
})

const getItemsFromData = (data: SettingsResponse) => {
  const settings = data?.profile_list_config?.settings ?? {}

  return Object.entries(settings)
    .map(([key, item]) => ({
      id: key,
      title: profilesSettingsMap[key].title,
      value: item.value,
      index: item.index
    }))
    .sort((a, b) => a.index - b.index)
}

export const getItemsFromDefaults = () => {
  return Object.entries(profilesSettingsMap)
    .map(([key, item], index) => ({
      id: key,
      title: item.title,
      index,
      value: item.defaultValue
    }))
    .sort((a, b) => a.index - b.index)
}

export type Item = SettingsItem & {
  title: string
}

export const TableSettings = ({
  onClose
}: {
  onClose: () => void
}) => {
  const [items, setItems] = useState<Item[]>([])

  const { data, isFetching } = useQuerySettings()

  const { mutate: saveSettings, isPending } = useMutationSettings()

  const loading = isPending || isFetching

  useEffect(() => {
    const list = loading
      ? []
      : data?.profile_list_config
        ? getItemsFromData(data)
        : getItemsFromDefaults()

    setItems(list)
  }, [data, loading])

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setItems(reorder(items, dragIndex, hoverIndex))
    },
    [items]
  )

  const onChange = (index: number, value: boolean) => {
    setItems(prev => {
      const newItems = [...prev]
      newItems[index].value = value
      return newItems
    })
  }

  const handleSubmit = () => {
    const newItems = items.reduce((acc, { id, value }, index) => {
      acc[id] = {
        index,
        value
      }
      return acc
    }, {} as ProfilesTableSettings)

    saveSettings({
      profile_list_config: { settings: newItems }
    })

    onClose()
  }

  const renderItem = (item: Item, index: number) => {
    return (
      <TableSettingsItem
        key={item.id}
        index={index}
        id={item.id}
        title={item.title}
        value={item.value}
        moveCard={moveCard}
        onChange={onChange}
      />
    )
  }
  return (
    <>
      <Items>{items.map((item, i) => renderItem(item, i))}</Items>

      <Loadable loading={loading}>
        <Box display='flex' sx={{ gap: 1 }}>
          <Button variant='contained' fullWidth onClick={onClose}>
            Отменить
          </Button>
          <Button variant='contained' fullWidth onClick={handleSubmit}>
            Сохранить
          </Button>
        </Box>
      </Loadable>
    </>
  )
}