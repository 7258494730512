import { authService } from '@/api/auth.service'
import App from '@/App'
import Home from '@/pages/Home/Home'
import { loginRoute } from '@/pages/Login/Login'
import { userRoute } from '@/pages/User/User'
import { usersIndexRoute, usersRoute } from '@/pages/Users/Users'
import {
  createRootRoute,
  createRoute,
  createRouter,
  Outlet,
  redirect,
} from '@tanstack/react-router'
import React, { Suspense } from 'react'

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? (): null => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )

export const rootRoute = createRootRoute({
  component: () => (
    <App>
      <Outlet />

      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </App>
  ),
})

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: Home,
  beforeLoad: async ({ location }) => {
    if (!authService.getToken()) {
      throw redirect({
        to: loginRoute.to,
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      })
    }
  },
})

const routeTree = rootRoute.addChildren([
  indexRoute.addChildren([usersRoute.addChildren([usersIndexRoute, userRoute])]),
  loginRoute,
])

export const router = createRouter({ routeTree })

export { usersRoute, loginRoute }
