import { useCountries } from '@/App'
import {
  CountrySelectContainer,
  FiltersContainer,
  InputsBlock,
  SelectedCountries
} from '@/pages/Users/Filters/styles'
import { Checkbox, FormControlLabel } from '@mui/material'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import CleanIcon from '@material-symbols/svg-400/rounded/mop.svg'
import { CountryMultiSelect } from '@roolz/sdk/components/ui/fields/CountryMultiSelect/CountryMultiSelect'
import { DatetimeField } from '@roolz/sdk/components/ui/fields/DatetimeField/DatetimeField'
import { State } from '@roolz/sdk/components/ui/fields/DatetimePicker/DatetimePicker'
import { Country } from '@roolz/types/custom'
import { FormApi } from 'final-form'
import { Field } from 'react-final-form'

export const getDefaultDate = (): State => ({
  date_from: null,
  date_to: null,
  time_to: null,
  time_from: null,
  timezone: null,
})

const MAX_COUNTRIES_COUNT = 5

type Props = {
  form: FormApi<any, Partial<any>>
}

export const Filters = ({ form }: Props) => {
  const { data: countries } = useCountries()

  return (
    <FiltersContainer>
      <Field name='reg_date'>
        {({ input: { value, onChange, ...rest } }) => (
          <DatetimeField
            placeholder='Выбрать'
            size='small'
            fullWidth={false}
            value={value}
            onChange={onChange}
            DatetimePickerProps={{
              disableTimeTillDateSpecified: true,
              allowTimeRange: false,
              allowSelectTime: false,
            }}
            {...rest}
          />
        )}
      </Field>

      <Field name='countries'>
        {({ input: { value, onChange } }) => (
          <div>
            <CountrySelectContainer>
              <CountryMultiSelect
                value={value}
                items={countries ?? []}
                onChange={onChange}
                placeholder='Выбрать страну'
                size='small'
              />
              <MaterialSymbolIcon
                onClick={value?.length ? () => onChange([]) : undefined}
                size={24}
                icon={<CleanIcon />}
              />
            </CountrySelectContainer>
            <SelectedCountries>
              {value
                ?.slice(0, MAX_COUNTRIES_COUNT)
                .map(({ alpha2 }: Country) => alpha2)
                .join(', ') + `${value?.length > MAX_COUNTRIES_COUNT ? '...' : ''}`}
            </SelectedCountries>
          </div>
        )}
      </Field>

      <Field name='last_action_date'>
        {({ input: { value, onChange } }) => (
          <DatetimeField
            placeholder='Выбрать'
            size='small'
            fullWidth={false}
            value={value}
            onChange={(data: State) =>
              onChange({
                ...data,
                date_from: data.date_from,
                date_to: data.date_from,
              })
            }
            DatetimePickerProps={{
              disableTimeTillDateSpecified: true,
              allowTimeRange: false,
              allowSelectTime: false,
            }}
          />
        )}
      </Field>

      <InputsBlock className='wide'>
        <Field name='has_not_companies'>
          {({ input: { value, onChange } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} onChange={onChange} />}
              label='Пользователь без компании'
            />
          )}
        </Field>

        <Field name='is_profile_unfilled'>
          {({ input: { value, onChange } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} onChange={onChange} />}
              label='Пользователь с незаполненным профилем'
            />
          )}
        </Field>
      </InputsBlock>

      <InputsBlock>
        <Field name='is_profile_banned'>
          {({ input: { value, onChange } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} onChange={onChange} />}
              label='Забаненные пользователи'
            />
          )}
        </Field>
        <Field name='has_banned_device'>
          {({ input: { value, onChange } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} onChange={onChange} />}
              label='Пользователи с забаненным девайсом'
            />
          )}
        </Field>
        <Field name='is_profile_deleted'>
          {({ input: { value, onChange } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} onChange={onChange} />}
              label='Удаленные пользователи'
            />
          )}
        </Field>
      </InputsBlock>
    </FiltersContainer>
  )
}
