import { styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
`

export const FieldContainer = styled('div')`
  display: flex;
  align-items: center;
`
