import { styled } from '@mui/material'
import { Link } from '@tanstack/react-router'

export const FiltersBlock = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  align-items: center;
`

export const UsersCount = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`

export const Wrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0;
`

export const BottomBlock = styled('div')`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(2)} 0;
`

//TODO вынести в шаред
export const Action = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  border: 1px solid transparent;
  transition: all 0.2s;

  &:hover,
  &.active {
    border-color: ${({ theme }) => theme.palette.grey[300]};
    background: ${({ theme }) => theme.palette.grey[50]};
  }
`

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
  &:hover,
  &:active,
  &:visited {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const SubmitActionsContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
`
