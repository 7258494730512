import { useCountries } from '@/App'
import { useUpdateProfileMutation } from '@/pages/User/model'
import { DeviceCard } from '@/pages/User/ui/DeviceCard/DeviceCard'
import { Field as EditField } from '@/shared/components/Field/Field'
import { BlockTitle, Column, Devices, FormBlock, MainGrid, ShowMoreDevices } from '@/pages/User/ui/ProfileForm/styles'
import { Label } from '@/shared/components/Label'
import { LoadingBackdrop } from '@/shared/components/LoadingBackdrop/LoadingBackdrop'
import { toastError } from '@roolz/sdk/components/snackbars'
import { AdminProfile, Profile, ProfileViewInfo } from '@roolz/types/api/profiles'
import { VALIDATION_STATUS_CODES } from '@roolz/types/custom'
import { createForm } from 'final-form'
import { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

type Props = {
  profile: AdminProfile
}

export const ProfileForm = ({ profile }: Props) => {
  const { t: errorsT } = useTranslation('errors')
  const { data: countries } = useCountries()
  const [showMoreDevices, setShowMoreDevices] = useState(false)
  const [fieldInEdit, setFieldInEdit] = useState<string | null>(null)

  const { mutate: updateProfile, isPending, isError, error } = useUpdateProfileMutation(profile.id)

  const showLoading = isPending

  const devices = showMoreDevices
    ? profile.devices
    : profile.devices.slice(0, 2)

  const {
    first_name,
    last_name,
    middle_name,
    about,
    site,
    work_phone,
    work_email,
    own_phone,
    own_email,
    skype
  } = profile.profile_view_info ?? {}

  const {
    nickname,
    id,
    country,
    phone,
    email,
    email_verified,
    phone_verified,
    google_id,
    facebook_id,
    apple_id,
    companies
  } = profile

  const initialValues = {
    first_name,
    last_name,
    middle_name,
    country,
    id,
    nickname,
    about,
    site,
    work_phone,
    work_email,
    own_phone,
    own_email,
    skype
  }

  const handleSubmit = (values: typeof initialValues) => {
    const profile_view_info =
    updateProfile({
      ...profile,
      profile_view_info: {
        ...profile.profile_view_info,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        about: values.about,
        site: values.site,
        work_phone: values.work_phone,
        work_email: values.work_email,
        own_phone: values.own_phone,
        own_email: values.own_email,
        skype: values.skype
      } as ProfileViewInfo,
      nickname: values.nickname,
      country_id: values.country?.id,
    })
  }

  const [form] = useState(() => createForm({
    initialValues,
    onSubmit: handleSubmit
  }))

  useEffect(() => {
    const e = error as any

    if(isError) {
      form.reset(initialValues)

      if(e?.response?.data?.error_code === VALIDATION_STATUS_CODES.INVALID_NICKNAME_FORMAT) {
        toastError('Невалидный формат никнейма')
        return
      }

      if(e?.response?.data?.error_code === VALIDATION_STATUS_CODES.ENTITY_ALREADY_EXISTS) {
        toastError('Такой никнейм уже занят')
        return
      }

      toastError(e?.response?.data?.error_msg ?? errorsT('insufficient_request'))
    }
  }, [isError])

  const getReadableBooleanValue = (value: boolean) => value ? 'Есть' : 'Нет'

  return (
    <MainGrid>
      <Form
        form={form}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, form }) => (
          <>
            <Column>
              <FormBlock>
                <BlockTitle>Общая информация</BlockTitle>
                <Field
                  name='first_name'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='Имя'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='middle_name'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='Отчество'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='last_name'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='Фамилия'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='nickname'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='Никнэйм'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='id'
                  render={({ input: { value, onChange } }) => (
                    <EditField
                      variant='text'
                      readonly
                      label='ID профиля'
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Field
                  name='about'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      multiline
                      label='Информация о пользователе'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='country'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='country'
                      label='Страна'
                      items={countries ?? []}
                      value={value}
                      onChange={onChange}
                      multiselect={false}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='site'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      multiline
                      label='Сайт'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
              </FormBlock>

              <FormBlock>
                <BlockTitle>Оферты</BlockTitle>
                <div>
                  <Label>Всего оферт:</Label>{' '}
                  <span>{profile.total_offers}</span>
                </div>
                <div>
                  <Label>Грузовые оферты:</Label>{' '}
                  <span>{profile.transport_offers}</span>
                </div>
                <div>
                  <Label>Транспортные оферты:</Label>{' '}
                  <span>{profile.cargo_offers}</span>
                </div>
                <div>
                  <Label>Публичное размещение:</Label>{' '}
                  <span>{profile.public_offers}</span>
                </div>
                <div>
                  <Label>Приватное размещение:</Label>{' '}
                  <span>{profile.private_offers}</span>
                </div>
              </FormBlock>

              <FormBlock>
                <BlockTitle>Ставки</BlockTitle>

              </FormBlock>
            </Column>

            <Column>
              <FormBlock>
                <BlockTitle>Контакты</BlockTitle>
                <Field
                  name='own_phone'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='Номер телефона'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='work_phone'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='Рабочий номер телефона'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='own_email'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='Email'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='work_email'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='Рабочий email'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
                <Field
                  name='skype'
                  render={({ input: { value, onChange, name } }) => (
                    <EditField
                      variant='text'
                      label='skype'
                      value={value}
                      onChange={onChange}
                      fieldInEdit={fieldInEdit}
                      setFieldInEdit={setFieldInEdit}
                      onSave={handleSubmit}
                      reset={form.reset}
                      name={name}
                    />
                  )}
                />
              </FormBlock>

              <FormBlock>
                <BlockTitle>Авторизация</BlockTitle>

                <EditField
                  variant='text'
                  readonly
                  label='Авторизационный номер телефона'
                  value={phone ?? ''}
                />
                <EditField
                  variant='text'
                  readonly
                  label='Подтверждение авторизационного номера телефона'
                  value={getReadableBooleanValue(phone_verified)}
                />
                <EditField
                  variant='text'
                  readonly
                  label='Авторизационный email'
                  value={email ?? ''}
                />
                <EditField
                  variant='text'
                  readonly
                  label='Подтверждение авторизационного email'
                  value={getReadableBooleanValue(email_verified)}
                />
                <EditField
                  variant='text'
                  readonly
                  label='Google_id'
                  value={getReadableBooleanValue(!!google_id)}
                />
                <EditField
                  variant='text'
                  readonly
                  label='Facebook_id'
                  value={getReadableBooleanValue(!!facebook_id)}
                />
                <EditField
                  variant='text'
                  readonly
                  label='Apple_id'
                  value={getReadableBooleanValue(!!apple_id)}
                />
              </FormBlock>
            </Column>

            <Column>
              <FormBlock>
                <BlockTitle>Компании и роли</BlockTitle>

                {companies?.map((company) => (
                  <div key={company.name}>
                    <span>{company.my_role}</span>
                    <span>{company.name}</span>
                  </div>
                ))}
              </FormBlock>

              <FormBlock>
                <BlockTitle>Девайсы пользователя</BlockTitle>

                <Devices>
                  {devices?.map((device) => (
                    <DeviceCard key={device.id} device={device} profileId={profile.id}/>
                  ))}

                  {profile.devices.length > 2 && (
                    <ShowMoreDevices
                      onClick={() => setShowMoreDevices(p => !p)}
                    >
                      Посмотреть все девайсы пользователя
                    </ShowMoreDevices>
                  )}
                </Devices>
              </FormBlock>
            </Column>
          </>
        )}
      </Form>

      {showLoading  && (
        <LoadingBackdrop loading/>
      )}
    </MainGrid>
  )
}
