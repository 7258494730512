import { ItemContainer } from '@/shared/components/TableSettings/styles'
import { Item } from '@/shared/components/TableSettings/TableSettings'
import { Checkbox, FormControlLabel } from '@mui/material'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import DragIcon from '@material-symbols/svg-400/rounded/drag_indicator.svg'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const DND_ITEM_KEY = 'settings_item'

type TableSettingsItemProps = Item & {
  onChange: (index: number, value: boolean) => void
  moveCard: (dragIndex: number, hoverIndex: number) => void
}

export const TableSettingsItem = ({
  id,
  title,
  index,
  moveCard,
  value,
  onChange
}: TableSettingsItemProps) => {
  const itemRef = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop<{ index: number; id: number | string }>({
    accept: DND_ITEM_KEY,
    hover(item, monitor) {
      if (!itemRef.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = itemRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = (clientOffset?.y ?? 0) - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_KEY,
    item: { id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(itemRef))

  return (
    <ItemContainer ref={itemRef} style={{ opacity, cursor: isDragging ? 'grabbing' : 'grab' }}>
      <MaterialSymbolIcon icon={<DragIcon />} />
      <FormControlLabel control={<Checkbox checked={value} onChange={(_, value) => onChange(index, value)} />} label={title} />
    </ItemContainer>
  )
}
