import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import EditIcon from '@material-symbols/svg-400/rounded/edit.svg'

type Props = {
  onClick: () => void
  disabled?: boolean
}

export const EditButton = ({ onClick, disabled }: Props) => {
  return (
    <MaterialSymbolIcon
      style={{
        color: disabled ? 'lightgrey' : 'black'
      }}
      size={24}
      icon={<EditIcon/>}
      onClick={!disabled ? onClick : undefined}
    />
  )
}