import { ReactNode, useMemo } from 'react'
import cn from 'classnames'
import { isEqual } from 'lodash-es'
import { IconButton, Popover, PopoverProps } from '@mui/material'
import { Clean } from '@roolz/icons/Clean'
import { Calendar, CalendarContext, CalendarContextInterface } from '@roolz/sdk/components'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { SelectField, SelectItem } from '@roolz/sdk/components/ui/fields/Select/SelectField'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import { getMaximalDate} from '@roolz/sdk/utils/date'
import { getTimezoneOffset } from '@roolz/sdk/utils/dateUtils/getTimezoneOffset'
import { padNumber } from '@roolz/sdk/utils/helpers'
import styles from './DatetimePicker.module.scss'

type Unixtime = number

export interface Props {
  anchorRef: any,
  open: boolean,
  setOpen: (value: boolean) => void,
  PopoverProps?: Partial<PopoverProps>

  onChange: (value: State) => void
  value: State,

  disableTimezoneOnTimeEmpty?: boolean
  disableTimeTillDateSpecified?: boolean
  allowTimeRange?: boolean
  allowSelectTime?: boolean

  isDateAllowed?: (date: Unixtime) => boolean
  isTimeFromAllowed?: (timeSeconds: number) => boolean
  isTimeToAllowed?: (timeSeconds: number, timeFrom?: number | null) => boolean

  datesNote?: string
  timeNote?: string

  Header?: ReactNode
}

export interface State {
  date_from: Unixtime | null,
  date_to: Unixtime | null,
  time_from: number | null,
  time_to: number | null,
  timezone: string | null
}

export const DatetimePicker = ({
  anchorRef,
  PopoverProps,
  open,
  setOpen,

  onChange,
  value,

  disableTimezoneOnTimeEmpty = true,
  disableTimeTillDateSpecified = false,
  isDateAllowed,
  isTimeFromAllowed,
  isTimeToAllowed,

  allowTimeRange = false,
  allowSelectTime = true,

  datesNote,
  timeNote,

  Header
}: Props) => {
  const { t } = useSdkTranslation('ui')

  const todayUTC = dayjs.utc().startOf('day')

  const initialState: State = {
    date_from: null,
    date_to: null,
    time_from: null,
    time_to: null,
    timezone: null
  }

  const isEmpty = isEqual(value, initialState)

  const weekDays: CalendarContextInterface['weekDays'] = t('datetime.week_days', { returnObjects: true }) as any
  const months: CalendarContextInterface['months'] = t('datetime.months', { returnObjects: true }) as any

  const defaultTimezoneOffsets: SelectItem[] = useMemo(() => getTimezoneOffset(), [])

  const allTimes: SelectItem[] = useMemo(() => {
    const defaultTimes = []

    for(let h = 0; h <= 23; h++) {
      for(let m = 0; m <= 45; m += 15) {
        defaultTimes.push({
          label: `${padNumber(h, 2)}:${padNumber(m, 2)}`,
          value: String(h * 3600 + m * 60)
        })
      }
    }

    return defaultTimes
  }, [])

  const timesFrom: SelectItem[] = useMemo(() => {
    if(!isTimeFromAllowed) return allTimes

    return allTimes.filter(({ value }) => isTimeFromAllowed(parseInt(value)))
  }, [allTimes, isTimeFromAllowed])

  const timesTo: SelectItem[] = useMemo(() => {
    if(!isTimeToAllowed) return allTimes

    return allTimes.filter(({ value: time }) => isTimeToAllowed(parseInt(time), value.time_from))
  }, [allTimes, isTimeToAllowed, value.time_from])


  function dateFormatter(date: Unixtime | null) {
    if(!date) return ''

    return dayjs.utc(date).format('DD.MM.YYYY')
  }

  const selectedDates: string = useMemo(() => {
    const arr = []

    if(value.date_from && value.date_to
      && dayjs(getMaximalDate()).isSame(value.date_to)
    ) {
      const today = dayjs.utc().startOf('day')
      const tomorrow = today.clone().add(1, 'day')
      const afterTomorrow = today.clone().add(2, 'days')

      if(today.isSame(value.date_from, 'day')) {
        return t('datetime.from_today')
      }
      if(tomorrow.isSame(value.date_from, 'day')) {
        return t('datetime.from_tomorrow')
      }
      if(afterTomorrow.isSame(value.date_from, 'day')) {
        return t('datetime.from_after_tomorrow')
      }

      return t('datetime.from_date') + ' ' + dateFormatter(value.date_from)
    }

    if(value.date_from) {
      arr.push(dateFormatter(value.date_from))

      if(value.date_to && !dayjs.utc(value.date_from).isSame(value.date_to, 'day')) {
        arr.push(dateFormatter(value.date_to))
      }
    }

    return arr.join(' - ')
  }, [value.date_from, value.date_to])

  function handleDatesChange(from: Unixtime | null, to: Unixtime | null): void {
    onChange({
      ...value,
      date_from: from,
      date_to: to
    })
  }

  function handleTimeFromChange(item: any) {
    onChange({
      ...value,
      time_from: item.target.value === '' ? null : Number.parseInt(item.target.value),
      time_to: item.target.value === '' ? null : value.time_to,
      timezone: (disableTimezoneOnTimeEmpty && item.target.value === '') ? null : value.timezone
    })
  }

  function handleTimeToChange(item: any) {
    onChange({
      ...value,
      time_to: item.target.value === '' ? null : Number.parseInt(item.target.value)
    })
  }

  function handleTimezoneChange(item: any) {
    onChange({
      ...value,
      timezone: item.target.value === '' ? null : item.target.value
    })
  }

  function handleClean() {
    onChange(initialState)
  }

  const isDateSpecified = useMemo(() => {
    return value.date_from !== null || value.date_to !== null
  }, [value])

  return (
    <Popover
      open={open}
      onClose={() => setOpen(false)}
      anchorEl={anchorRef.current}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      classes={{
        root: styles.root,
        paper: styles.paper
      }}
      disablePortal={true}
      TransitionProps={{
        timeout: { enter: 250, exit: 160 }
      }}
      {...PopoverProps}
    >
      <CalendarContext.Provider value={{ weekDays, months }}>
        {/*<ClickAwayListener onClickAway={() => setOpen(false)}>*/}
        <div className={styles.content}>
          <div className={styles.header}>

            {Header || (
              <span className={styles.label}>
                {datesNote}
              </span>
            )}

            <span className={styles.selectedDates}>
              {selectedDates}
            </span>
          </div>

          <div className={styles.calendar__wrapper}>
            <Calendar
              value={[value.date_from, value.date_to]}
              range={true}
              onChange={handleDatesChange}
              isDateAllowed={isDateAllowed}
            />
          </div>


          {allowSelectTime && (<>
            <div className={cn(styles.label, styles.footerLabel)}>
              {timeNote}
            </div>
          </>)}


          <div className={styles.footer}>
            {allowSelectTime && (<>
              <div className={styles.timeRange}>
                <SelectField
                  className={styles.timeRange__picker}
                  emptyLabel={t('datetime.time.any')}
                  items={timesFrom}
                  disabled={disableTimeTillDateSpecified && !isDateSpecified}
                  value={typeof value.time_from === 'number' ? String(value.time_from) : ''}
                  onChange={handleTimeFromChange}
                />

                {allowTimeRange && (<>
                  <div className={styles.timeRange__divider}/>

                  <SelectField
                    className={styles.timeRange__picker}
                    emptyLabel={t('datetime.time.any')}
                    items={timesTo}
                    disabled={disableTimeTillDateSpecified && !isDateSpecified}
                    value={typeof value.time_to === 'number' ? String(value.time_to) : ''}
                    onChange={handleTimeToChange}

                    SelectProps={{
                      disabled: value.time_from === null
                    }}
                  />
                </>)}

                <SelectField
                  className={styles.timeRange__timezone}
                  emptyLabel={t('datetime.local_time')}
                  items={defaultTimezoneOffsets}
                  disabled={(disableTimeTillDateSpecified && !isDateSpecified) || (disableTimezoneOnTimeEmpty && value.time_from === null)}
                  value={value.timezone === null ? '' : value.timezone}
                  onChange={handleTimezoneChange}
                />
              </div>
            </>)}

            <div className={styles.actions}>
              <IconButton
                className={cn(styles.cleanButton, {
                  [styles.cleanButtonHidden]: isEmpty
                })}
                onClick={handleClean}
              >
                <Clean/>
              </IconButton>

              <Button
                className={styles.closeButton}
                onClick={() => setOpen(false)}
                disabled={isEmpty}
              >
                {t('datetime.confirm')}
              </Button>
            </div>
          </div>
        </div>
        {/*</ClickAwayListener>*/}
      </CalendarContext.Provider>
    </Popover>
  )
}
