import { refreshTokensInterceptor } from '@/api/refreshTokensInterceptor'
import i18n from '@/config/i18n'

import { ApiClients } from '@roolz/api/clients'
import { createApi } from '@roolz/api/index'

const clients = new ApiClients({
  accessToken: () => localStorage.getItem('token'),
  language: () => i18n.language,
  ownServerBaseUrl: process.env.REACT_APP_OWN_SERVER_BASE_URL!,
  isRU: () => false
})

clients.exchange.interceptors.request.use(refreshTokensInterceptor)
clients.identity.interceptors.request.use(refreshTokensInterceptor)
clients.knowledge.interceptors.request.use(refreshTokensInterceptor)
clients.messaging.interceptors.request.use(refreshTokensInterceptor)
clients.wsProxy.interceptors.request.use(refreshTokensInterceptor)
clients.fileServer.interceptors.request.use(refreshTokensInterceptor)
clients.hereProxy.interceptors.request.use(refreshTokensInterceptor)

export const api = createApi(clients)
