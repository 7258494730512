import { api } from '@/api/index'
import { ProfilesTableSettings } from '@/pages/Users/model'
import { SettingsResponse } from '@roolz/api/api/identity/admin.api'


class ConfigService {
  getSettings() {
    return api.admin.getSettings()
  }

  //TODO
  saveSettings(body: Partial<SettingsResponse>) {
    return api.admin.saveSettings(body)
  }
}

export const configService = new ConfigService()
