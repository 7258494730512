import { BackBtn, HeaderContainer } from '@/pages/User/ui/Header/styles'
import { ProfileInfo } from '@/pages/User/ui/ProfileInfo/ProfileInfo'
import { usersRoute } from '@/pages/Users/Users'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import BackIcon from '@material-symbols/svg-400/rounded/arrow_back.svg'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { AdminProfile, Profile } from '@roolz/types/api/profiles'
import { OnlineStatus } from '@roolz/types/custom'
import { useNavigate } from '@tanstack/react-router'

type Props = {
  profile: AdminProfile
}

export const Header = ({ profile }: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <BackBtn onClick={() => navigate(usersRoute)}>
        <MaterialSymbolIcon icon={<BackIcon />} />
      </BackBtn>
      <HeaderContainer>
        <ProfileInfo profile={profile}/>
      </HeaderContainer>
    </>
  )
}
