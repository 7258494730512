import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react'

type EditContext = { isEditable: boolean; toggleEdit: () => void }

const editContext = createContext<EditContext>({
  isEditable: false,
  toggleEdit: () => {},
})

export const useEditContext = () => useContext(editContext)

const Provider = editContext.Provider

export const EditContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isEditable, setIsEditable] = useState(false)

  const value = useMemo(
    () => ({
    isEditable,
      toggleEdit: () => setIsEditable((p) => !p),
    }),
    [isEditable],
  )

  return <Provider value={value}>{children}</Provider>
}
