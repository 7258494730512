import { styled } from '@mui/material'

export const BackBtn = styled('div')`
  display: inline-flex;
  width: fit-content;
  padding: ${({ theme }) => theme.spacing(1)};
  padding-top: 0;
  cursor: pointer;
`

export const HeaderContainer = styled('header')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`
