import { authService } from '@/api/auth.service'
import { indexRoute, rootRoute, router } from '@/config/routes'
import { Container, Form } from '@/pages/Login/styles'
import { Button, TextField } from '@mui/material'
import { Loadable } from '@roolz/sdk/components'
import { toastError } from '@roolz/sdk/components/snackbars'
import { getDeviceInfo } from '@roolz/sdk/utils/device'
import { createRoute, redirect, useNavigate } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const Login = () => {
  const navigate = useNavigate()
  const { redirect } = loginRoute.useSearch()
  const { t: errorsT } = useTranslation('errors')
  const [isLoading, setIsLoading] = useState(false)

  const [loginValue, setLoginValue] = useState('admin@test.dsf')
  const [password, setPassword] = useState('admin@test.dsf1')

  const login = async () => {
    setIsLoading(true)
    try {
      setIsLoading(true)
      await authService.loginWithEmail({
        auth_email: loginValue,
        auth_password: password,
        device: getDeviceInfo()
      })
    } catch(e) {
      toastError(e?.response?.data?.error_msg ?? errorsT('insufficient_request'))
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    await login()

    if(redirect) {
      return router.history.push(redirect)
    }

    navigate(indexRoute)
  }

  return (
    <Container>
      <Form>
        <TextField
          value={loginValue}
          onChange={(e) => setLoginValue(e.target.value)}
          fullWidth
          type='email'
          placeholder='email'
        />
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          type='password'
          placeholder='password'
        />
        <Loadable loading={isLoading}>
          <Button size='large' fullWidth onClick={handleSubmit} variant='contained'>
            Enter
          </Button>
        </Loadable>
      </Form>
    </Container>
  )
}

export const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: 'login',
  beforeLoad: async () => {
    if(authService.getToken()) {
      throw redirect({
        to: indexRoute.to
      })
    }
  },
  validateSearch: (search: Record<string, unknown>): { redirect?: string } => {
    return {
      redirect: (search.redirect) as string || undefined
    }
  },
  component: Login
})
