import { api } from '@/api'
import { queryClient } from '@/index'
import { UpdateProfileRequest } from '@roolz/api/api/identity/profiles.api'
import { AdminDevice, AdminProfile } from '@roolz/types/api/profiles'
import { useMutation, useQuery } from '@tanstack/react-query'

export const useGetProfileQuery = (id: AdminProfile['id']) => useQuery({
  queryKey: [id],
  queryFn: async () => api.profiles.getProfileById(id)
})

export const useUpdateProfileMutation = (id: AdminProfile['id']) => useMutation({
  mutationKey: [id],
  mutationFn: async (data: UpdateProfileRequest) => {
    return api.profiles.updateProfileById(id, data)
  },
  onSuccess: (data) => {
    queryClient.setQueryData([id], data)
  }
})

export const useBanProfileMutation = (profile_id: AdminProfile['id']) => useMutation({
  mutationKey: [profile_id],
  mutationFn: async (body: { duration: string }) => {
    return api.profiles.banProfile(profile_id, body)
  },
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: [profile_id]
    })
  }
})

export const useDeleteProfileMutation = (profile_id: AdminProfile['id']) => useMutation({
  mutationKey: [profile_id],
  mutationFn: async (body: { reason: string }) => {
    return api.profiles.deleteProfile(profile_id, body)
  },
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: [profile_id, 'profiles']
    })
  }
})

export const useUnbanProfileMutation = (profile_id: AdminProfile['id']) => useMutation({
  mutationKey: [profile_id],
  mutationFn: async () => {
    return api.profiles.unbanProfile(profile_id)
  },
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: [profile_id]
    })
  }
})

export const useUnbanDeviceMutation = (device_id: AdminDevice['id'], profile_id: AdminProfile['id']) => useMutation({
  mutationKey: ['devices', device_id],
  mutationFn: async () => {
    return api.profiles.unbanDevice(device_id)
  },
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: [profile_id]
    })
  }
})

export const useBanDeviceMutation = (device_id: AdminDevice['id'], profile_id: AdminProfile['id']) => useMutation({
  mutationKey: ['devices', device_id],
  mutationFn: async () => {
    return api.profiles.banDevice(device_id)
  },
  onSuccess: () => {
    queryClient.invalidateQueries({
      queryKey: [profile_id]
    })
  }
})
