export const getTimezoneOffset = () => {
  const gmtPlus = []
  const gmtMinus = []

  for (let i = 1; i <= 12; i++) {
    gmtPlus.push({ value: `${i}`, label: `GMT +${i}` })
    gmtMinus.push({ value: `${-i}`, label: `GMT ${-i}` })
  }

  return [...gmtPlus.toReversed(), { value: '0', label: 'UTC' },  ...gmtMinus]
}
