import { styled, css } from '@mui/material'

export const Height = css`
  height: 300px;
`

export const FiltersContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: stretch;
  align-items: stretch;
  gap: ${({ theme }) => theme.spacing(2)};

  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};

  & .MuiInputBase-root {
    height: 100%;
  }
`

export const InputsBlock = styled('div')`
  display: inline-flex;
  flex-direction: column;

  &.wide {
    grid-column-end: span 2;
  }

  & .MuiFormControlLabel-root {
    width: fit-content;
  }
`

export const CountrySelectContainer = styled('div')`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  & .MuiAutocomplete-root {
    width: 100%;
  }
`

export const SelectedCountries = styled('p')`
  font-size: 14px;
`
