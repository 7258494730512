import { styled } from '@mui/material'

export const ItemContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  transition: all 0.2s;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  cursor: move; /* fallback if grab cursor is unsupported */

  & .MuiCheckbox-root {
    padding: 4px;
  }
`

export const Items = styled('div')`
  max-height: 60vh;
  overflow-y: auto;
`