import { useBanProfileMutation, useDeleteProfileMutation } from '@/pages/User/model'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
  TextField
} from '@mui/material'
import { AdminProfile } from '@roolz/types/api/profiles'
import { useState } from 'react'

export const DeleteModal = ({
  open,
  handleClose,
  profile
}: {
  open: boolean
  handleClose: () => void
  profile: AdminProfile
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [reason, setReason] = useState('')

  const { mutate: deleteProfile } = useDeleteProfileMutation(profile.id)

  const handleSubmit = () => {
    deleteProfile({ reason })
  }

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          Почему вы хотите удалить аккаунт?
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Опишите причины. Так мы сможем сделать продукт лучше
          </DialogContentText>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder='Причины'
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            error={!reason}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button disabled={!reason} onClick={() => setConfirmOpen(true)}>Продолжить</Button>
        </DialogActions>
      </Dialog>

      <DeleteConfirmModal
        open={confirmOpen}
        handleSubmit={() => {
          setConfirmOpen(false)
          handleSubmit()
          handleClose()
        }}
        handleClose={() => {
          setConfirmOpen(false)
          handleClose()
        }}
      />
    </>
  )
}

const durations = [
  {
    value: '24h',
    label: '24 часа'
  },
  {
    value: '168h',
    label: '168 часов'
  },
  {
    value: '1843200h',
    label: 'Навсегда'
  },
]

export const BanModal = ({
  profile,
  open,
  handleClose
}: {
  profile: AdminProfile
  open: boolean
  handleClose: () => void
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [duration, setDuration] = useState(durations[0].value)

  const { mutate: banProfile } = useBanProfileMutation(profile.id)

  const handleSubmit = () => {
    banProfile({ duration })
  }

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          Выберите длительность бана пользователя
        </DialogTitle>

        <DialogContent>
          <FormControl>
            <FormLabel>Длительность бана</FormLabel>
            <RadioGroup
              name="radio-buttons-group"
              value={duration}
              onChange={(_, value) => setDuration(value)}
            >
              {durations.map(({ value, label }) => (
                <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={() => setConfirmOpen(true)}>Забанить</Button>
        </DialogActions>
      </Dialog>

      <BanConfirmModal
        open={confirmOpen}
        handleSubmit={() => {
          setConfirmOpen(false)
          handleSubmit()
          handleClose()
        }}
        handleClose={() => {
          setConfirmOpen(false)
          handleClose()
        }}
      />
    </>
  )
}

export const DeleteConfirmModal = ({
  open,
  handleClose,
  handleSubmit
}: {
  open: boolean
  handleClose: () => void
  handleSubmit: () => void
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        Вы уверены, что хотите удалить аккаунт?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          После удаления аккаунт нельзя будет восстановить
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button onClick={handleSubmit}>Удалить</Button>
      </DialogActions>
    </Dialog>
  )
}


export const BanConfirmModal = ({
  open,
  handleClose,
  handleSubmit
}: {
  open: boolean
  handleClose: () => void
  handleSubmit: () => void
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>
        Вы уверены, что хотите забанить пользователя?
      </DialogTitle>

      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button onClick={handleSubmit}>Забанить</Button>
      </DialogActions>
    </Dialog>
  )
}