import { styled } from '@mui/material'

export const PageContainer = styled('div')`
  max-width: 100%;
  min-width: 1px;
  height: 100vh;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 20px;
`
