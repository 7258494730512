import { CountrySelect, CountrySelectProps } from '@/shared/components/CountrySelect/CountrySelect'
import { Container, FieldContainer } from '@/shared/components/Field/styles'
import { Actions, Icon } from '@/pages/User/ui/ProfileForm/styles'
import { EditButton } from '@/shared/components/EditButton/EditButton'
import { useEditContext } from '@/shared/context/editContext'
import { Label } from '@/shared/components/Label'
import CloseIcon from '@material-symbols/svg-400/rounded/close.svg'
import DoneIcon from '@material-symbols/svg-400/rounded/done.svg'
import { TextField, TextFieldProps } from '@mui/material'
import { Country } from '@roolz/types/custom'
import { useId } from 'react'

type BaseProps = {
  variant: 'text' | 'country'
  value: string
  label: string
  readonly?: boolean
  fieldInEdit?: string | null
  setFieldInEdit?: (edit: null | string) => void
  onSave?: () => void
  reset?: () => void
  name?: string
}

type TextProps = Omit<TextFieldProps, 'variant'> & {
  variant: 'text'
}

type CountryProps = Omit<CountrySelectProps, 'variant'> & {
  variant: 'country'
  value: Country
}

type Props = BaseProps & (TextProps | CountryProps)

export const Field = (props: Props) => {
  const {
    setFieldInEdit,
    onSave,
    reset,
    name,
    fieldInEdit
  } = props
  const { isEditable } = useEditContext()
  const htmlId = useId()

  const isEdit = name === fieldInEdit
  const isAnotherFieldInEdit = fieldInEdit && !isEdit

  const handleSave = () => {
    onSave?.()
    setFieldInEdit?.(null)
  }

  const handleReset = () => {
    reset?.()
    setFieldInEdit?.(null)
  }

  const fieldProps = {
    id: htmlId,
    fullWidth: true,
    size: 'small' as const,
    disabled: !isEdit
  }

  const { variant, label, value, readonly, ...rest } = props

  const renderField = () => {
    if(readonly) {
      return (
        <TextField value={value} {...fieldProps} disabled/>
      )
    }

    if(props.variant === 'text') {
      return (
        <TextField
          value={value}
          {...fieldProps}
          {...rest}
        />
      )
    }

    if(props.variant === 'country' && props.isEdit) {
      return (
        <CountrySelect
          value={value}
          countries={props.items}
          {...fieldProps}
          {...rest}
        />
      )
    }

    if(props.variant === 'country') {
      return (
        <CountrySelect
          value={value}
          countries={props.items}
          {...fieldProps}
          {...rest}
          disabled
        />
      )
    }
  }

  return (
    <Container>
      <Label htmlFor={htmlId}>{props.label}</Label>
      <FieldContainer>
        {renderField()}

        {!readonly && (
          <Actions>
            {isEditable ? (
              isEdit ? (
                <>
                  <Icon color='#0b48dc' onClick={handleSave} size={24} icon={<DoneIcon/>}/>
                  <Icon color='red' onClick={handleReset} size={24} icon={<CloseIcon/>}/>
                </>
              ) : (
                <EditButton disabled={!!isAnotherFieldInEdit} onClick={() => setFieldInEdit?.(name ?? null)}/>
              )
            ) : null}
          </Actions>
        )}
      </FieldContainer>
    </Container>
  )
}
