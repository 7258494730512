import { authService } from '@/api/auth.service'
import { loginRoute, usersRoute } from '@/config/routes'
import { Button } from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import { Container, LinkStyled } from './styles'

export const MainSidebar = () => {
  const navigate = useNavigate()

  const handleLogout = () => {
    authService.logout()
    navigate(loginRoute)
  }

  return (
    <Container>
      <LinkStyled to={usersRoute.to}>users</LinkStyled>
      //TODO styles
      <Button onClick={handleLogout} style={{marginTop: 'auto'}}>Logout</Button>
    </Container>
  )
}
