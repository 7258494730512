import { SIDEBAR_WIDTH } from '@/shared/styles/const'
import { styled } from '@mui/material'
import { Link } from '@tanstack/react-router'

export const Container = styled('div')`
  position: sticky;
  top: 0;
  left: 0;
  min-width: ${SIDEBAR_WIDTH};
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.grey['50']};
  -webkit-box-shadow: 4px 0px 18px -2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 0px 18px -2px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 0px 18px -2px rgba(34, 60, 80, 0.2);
`

export const LinkStyled = styled(Link)`
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.primary.dark};

  &.active {
    background-color: ${({ theme }) => theme.palette.action.selected};
    border-radius: ${({ theme }) => theme.spacing(1)};
  }
`
