import { api } from '@/api'
import { useDeleteProfileMutation, useUnbanProfileMutation, useUpdateProfileMutation } from '@/pages/User/model'
import { BanModal, DeleteModal } from '@/pages/User/ui/ProfileInfo/Modals'
import {
  ActionsContainer,
  ActivityInfo,
  Complaints, MenuItem,
  ProfileInfoContainer
} from '@/pages/User/ui/ProfileInfo/styles'
import { getFormattedDate } from '@/pages/Users/model'
import { ProductAvatar } from '@/shared/components/ProductAvatar/ProductAvatar'
import { useEditContext } from '@/shared/context/editContext'
import {
  Button,
  Switch,
  Typography
} from '@mui/material'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { AvatarUpload } from '@roolz/sdk/components/ui/fields/AvatarUpload/AvatarUpload'
import { MenuList } from '@roolz/sdk/components/ui/MenuList/MenuList'
import { MenuListContent } from '@roolz/sdk/components/ui/MenuList/MenuListContent/MenuListContent'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { uploadFileMultipartRequest } from '@roolz/types/api/files'
import { AdminProfile, Profile, ProfileViewInfo } from '@roolz/types/api/profiles'
import { OnlineStatus } from '@roolz/types/custom'
import { useRef, useState } from 'react'

type Props = {
  profile: AdminProfile
}

const ONLINE_LAST_ACTION_TIME_MINS = 3
const ONLINE_RECENTLY_LAST_ACTION_TIME_MINS = 30

export function getProfileOnlineStatus(lastOnline: string) {
  const diffMins = dayjs()
    .diff(lastOnline, 'minutes')

  if(diffMins < ONLINE_LAST_ACTION_TIME_MINS) {
    return OnlineStatus.Online
  } else if(diffMins < ONLINE_RECENTLY_LAST_ACTION_TIME_MINS) {
    return OnlineStatus.Recently
  }

  return OnlineStatus.Offline
}


export const ProfileInfo = ({ profile }: Props) => {
  const anchorRef = useRef(null)
  const { isEditable, toggleEdit } = useEditContext()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [banModalOpen, setBanModalOpen] = useState(false)

  const { mutateAsync: updateProfile } = useUpdateProfileMutation(profile.id)
  const { mutate: unbanProfile } = useUnbanProfileMutation(profile.id)

  const { first_name, last_name, avatar } = profile.profile_view_info ?? {}
  const isBanned = !!profile.banned_at

  const handleChange = async ({ url }: { url: string | null }) => {
    await updateProfile({
      profile_view_info: {
        ...profile?.profile_view_info,
        avatar: url
      } as ProfileViewInfo
    })
  }

  const handleDeleteAvatar = async () => {
    await updateProfile({
      profile_view_info: {
        ...profile?.profile_view_info,
        avatar: null
      } as ProfileViewInfo
    })
  }

  const uploadFileByMultipart = async (body: Omit<uploadFileMultipartRequest, 'profile_id'>) => {
    const { data } = await api.fileServer.uploadFileMultipart({
      ...body,
      profile_id: profile.id
    })

    return data
  }

  return (
    <>
      <ProfileInfoContainer>
        {isEditable ? (
          <AvatarUpload
            first_name={first_name ?? ''}
            last_name={last_name ?? ''}
            color={profile.color}
            avatarUrl={avatar ?? null}
            type='profile'
            uploadFileByMultipart={uploadFileByMultipart}
            onChange={handleChange}
            onDelete={handleDeleteAvatar}
          />
        ) : (
          <Avatar
            width={160}
            first_name={first_name}
            last_name={last_name}
            color_code={profile.color}
            avatarUrl={avatar}
            onlineStatus={profile.last_action_time ? getProfileOnlineStatus(profile.last_action_time) : undefined}
          />
        )}

        <Complaints>
          <Typography variant='body1'>Жалобы НА пользователя: 3</Typography>
          <Typography variant='body1'>Жалобы ОТ пользователя: 5</Typography>
        </Complaints>

        <ProductAvatar product='driver'/>

        <ActivityInfo>
          <p>Последняя активность: {getFormattedDate(profile.last_action_time)}</p>
          {profile.banned_at && (
            <p className='red'>
              {dayjs(profile.banned_to).diff(dayjs(), 'h') > 168
                ? 'Бан: Навсегда'
                : `Бан: осталось ${dayjs(profile.banned_to).diff(dayjs(), 'h')} часов (${
                  dayjs(profile.banned_to).diff(dayjs(profile.banned_at), 'h')
                })`}
            </p>
          )}
          {profile.deletion_reason && (
            <p className='red'>
              {/*{`Аккаунт удален: ${getFormattedDate(profile.created_at)} по причине ${profile.deletion_reason}`}*/}
              {`Аккаунт удален: TODO по причине "${profile.deletion_reason}"`}
            </p>
          )}
        </ActivityInfo>

        <ActionsContainer>
          <Button variant='outlined' size='small' ref={anchorRef}>
            Действия Модератора
          </Button>
          <MenuList anchorRef={anchorRef}>
            <MenuListContent>
              <MenuItem
                onClick={() => setDeleteModalOpen(true)}
                label='Удалить профиль'
              />
              {isBanned
                ? (
                  <MenuItem
                    onClick={unbanProfile}
                    label='Разбанить пользователя'
                  />
                ) : (
                  <MenuItem
                    onClick={() => setBanModalOpen(true)}
                    label='Забанить пользователя'
                  />
                )}
            </MenuListContent>
          </MenuList>

          <Typography variant='body1'>Режим редактирования</Typography>
          <Switch checked={isEditable} onChange={toggleEdit}/>
        </ActionsContainer>
      </ProfileInfoContainer>

      <DeleteModal profile={profile} open={deleteModalOpen} handleClose={() => setDeleteModalOpen(false)}/>
      <BanModal profile={profile} open={banModalOpen} handleClose={() => setBanModalOpen(false)}/>
    </>
  )
}
