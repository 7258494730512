import { authService } from '@/api/auth.service'
import { AxiosRequestConfig } from 'axios'

export async function refreshTokensInterceptor(config: AxiosRequestConfig) {
  try {
    await authService.refreshTokenIfNecessary()
  } catch(e) {
    console.error(e)
  }

  return config
}
